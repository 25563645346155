import moment from "moment";
import React from "react";
import persistentStore from "../../stores/persistentStore";
import {secondsToTime} from "../../utils/helpers";
import {stripnamePlural} from "../../utils/stripname";
import {useTranslation} from "react-i18next";
import {ICard} from "../../interfaces/ICard";

const CardTable = ({cards}: {cards: ICard[]}) => {
    const {t} = useTranslation()

    const scrollToCard = (event: any) => {
        const cardId = event.currentTarget.dataset.id;
        const location = document.location.href;
        const hashLocation = location.lastIndexOf('#');

        if( hashLocation === -1){
            document.location.href += `#${cardId}`;
            return;
        }

        document.location.href = location.substring(0, hashLocation+1) + cardId;
    };

    const useTime = persistentStore.user?.settings.useType === 'hours'

    return (
        <div className='cardtable'>
            <h2 style={{textTransform: 'capitalize'}}>{useTime ? 'Strippen' : stripnamePlural()}{t('card')} Overzicht</h2>
            <p>In dit overzicht heb je een kort en bondig overzicht van alle {persistentStore.user?.settings?.stripName.singular}kaarten.</p>
            <p>Klik op een {persistentStore.user?.settings?.stripName.singular}kaart om naar die {persistentStore.user?.settings?.stripName.singular}kaart te gaan.</p>

            <table id="card-overview" className="table table-striped table-hover" width="100%">
                <thead>
                <tr>
                    <td>Datum aanschaf</td>
                    <td>{persistentStore.user?.settings?.stripName.plural} op kaart</td>
                    <td>{useTime ? 'Beschikbaar' : `Beschikbare ${persistentStore.user?.settings?.stripName.plural}`}</td>
                </tr>
                </thead>
                <tbody>
                {(cards || []).map((card: ICard) => (
                    <tr data-id={card.id} onClick={scrollToCard} key={card.id}>
                        <td data-id={card.id}>{moment(card.created_at).format('Y-MM-DD HH:mm')}</td>
                        <td data-id={card.id}>{useTime ? secondsToTime(card.time_in_seconds) : card.amount}</td>
                        <td data-id={card.id}>{useTime ? secondsToTime(card.stats.time_in_seconds_available) : card.stats.strips_available}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default CardTable;
