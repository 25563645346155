import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';
import {customers} from '../../api/customers';
import {useRouter} from '../../utils/router';
import {toast} from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert/dist';
import {useLoading} from '../../utils/helpers';
import {useTranslation} from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import persistentStore from '../../stores/persistentStore';

const CustomerAdd = (props) => {
    const [error, setError] = useState({email: undefined, name: undefined});
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [customerIsRemoved, setCustomerIsRemoved] = useState(false);
    const [loading, startLoading, stopLoading] = useLoading();
    const router = useRouter();
    const {t} = useTranslation();

    // when the form is submitted
    const handleSubmit = (event) => {
        event.preventDefault();

        // reset errors
        setError({email: '', name: ''});

        startLoading();

        customers.add(
            {user_id: persistentStore.user.id, email: email, name: name}).then(
            (response) => {
                stopLoading();

                if (!response.error) {
                    return response;
                }

                if (response.error.error !== '') {
                    toast.error(response.error.error);
                }

                // if (response.error.error) {
                //     toast.error(response.error.error)
                // }
            }).then((customer) => {
            // refetch customers
            customers.getAll();
            // go to customers page
            if (customer) {
                toast.success('Klant aangemaakt');
                router.push('/customers');
            }
        }).catch(errorFromServer => {
            const response = errorFromServer.response.data;
            stopLoading();

            let mailMessage = response.errors?.title;
            if (mailMessage === 'This email address is already in use.') {
                mailMessage = t('This email address is already in use');
            }

            if (response.message ===
                'Value "" is empty, but non empty value was expected.') {
                const updatedError = Object.assign(error,
                    {name: 'Customer name is required.'});
                setError(updatedError);
            }

            const updatedError = Object.assign(error, {email: mailMessage});
            setError(updatedError);

            if (mailMessage === 'Customer deleted') {
                setCustomerIsRemoved(true);
            }
        });
    };

    const reviveCustomer = () => {
        setCustomerIsRemoved(false);
        startLoading();
        customers.revive(email).then(result => {
            stopLoading();
            toast.success('Klant is weer beschikbaar');
            router.push(`/customers/${result.data.customer.public_id}`);
        });
    };

    const RemovedCustomerAlert = () => (
        <SweetAlert
            showCancel
            showCloseButton
            onConfirm={reviveCustomer}
            onCancel={() => setCustomerIsRemoved(false)}
            title={'Je hebt deze klant verwijderd'}
        >
            <strong>Wil je de klant uit de prullenbak halen?</strong>
        </SweetAlert>
    );

    return (
        <>
            <h1>Klant toevoegen</h1>

            {customerIsRemoved && <RemovedCustomerAlert/>}

            <form id="addCustomer" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="inputName">Klantnaam *</label>
                    <input
                        type="text"
                        className="form-control"
                        id="inputName"
                        aria-describedby="nameHelp"
                        placeholder="De klantnaam"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        name="name"
                    />
                    {error.name &&
                    <div className="alert alert-warning"
                         role="alert">{error.name}</div>}
                </div>

                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">E-mailadres</label>
                    <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Het e-mailadres van de klant"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                    />

                    {error.email && (
                        <div className="alert alert-warning"
                             role="alert">{error.email}</div>
                    )}
                </div>

                <Link to={`/customers`}>
                    <button type="button" className="btn btn-link">Annuleren
                    </button>
                </Link>
                &nbsp;
                <Button
                    type="submit"
                    size="lg"
                    id="customer-save"
                    className="btn btn-primary customer-add"
                    disabled={loading}
                >
                    {loading &&
                    <Spinner as="span" animation="border" size="sm"
                             role="status"
                             aria-hidden="true"/>}
                    {!loading && t('Save')}
                </Button>
            </form>
        </>
    );
};

export default observer(CustomerAdd);
