import {action, decorate, observable} from 'mobx'
import {IUser} from "../interfaces/IUser";
import {ICardType} from "../interfaces/ICardType";
import persistentStore from "./persistentStore";

class Store {
    cardTypes: ICardType[] = []
    company: string = 'Strippenkaart App'
    enableSales = false
    locale = 'nl-NL'
    logoUploadLimit = 2
    saEvent: any = undefined
    sbEvent: any = undefined
    selectedLang: string = 'nl'
    showUpgrade: boolean = false
    userView: IUser | undefined = undefined

    setShowUpgrade = (show: boolean) => {
        this.showUpgrade = show
    }

    setSaEvent(saEvent: any) {
        this.saEvent = saEvent
    }

    // empty...
    emptyStore() {
        persistentStore.setUser(undefined)
        this.cardTypes = []
    }

    setCompany(company: string) {
        this.company = company
    }

    setCardTypes(cardTypes: ICardType[]) {
        this.cardTypes = cardTypes
    }

    setSelectedLang(language: string) {
        if (['nl', 'en'].includes(language)) {
            this.selectedLang = language
        } else {
            throw new Error('Trying to set non-existing language: ' + language)
        }
    }

    setUserView(user: IUser) {
        this.userView = user
    }

    setLocale(locale: string) {
        this.locale = locale
    }

    // triggerEvent(eventName: string) {
    //     // this.triggerSimpleAnalyticsEvent(eventName)
    //     // this.triggerSplitBeeEvent(eventName)
    // }

    // triggerSplitBeeEvent(eventName: string, eventData: any = undefined) {
    //     // @ts-ignore
    //     if (!this.sbEvent && !window.splitbee) {
    //         console.error('event triggered, but splitbee is not available')
    //     }
    //
    //     // @ts-ignore
    //     if (!this.sbEvent && window.splitbee) {
    //         console.log('setting splitbee event from the store (was not set yet)')
    //         // wasn't registered yet, so let's do that now
    //         // @ts-ignore
    //         this.sbEvent = window.splitbee
    //     }
    //
    //     this.sbEvent.track(eventName, eventData)
    // }

    // triggerSimpleAnalyticsEvent(eventName: string){
    //     // @ts-ignore
    //     if (!this.saEvent && !window.sa_event) {
    //         console.error('event triggered, but sa_event is not available')
    //     }
    //
    //     // @ts-ignore
    //     if (!this.saEvent && window.sa_event) {
    //         console.log('setting sa_event from the store (was not set yet)')
    //         // wasn't registered yet, so let's do that now
    //         // @ts-ignore
    //         this.saEvent = window.sa_event
    //     }
    //
    //     this.saEvent(eventName)
    // }

    setUnitAdderSentMailByDefault(enabled: boolean) {
        if (persistentStore.user) {
            const unitAdderSettings = persistentStore.user.settings.unitAdder
            unitAdderSettings.mailByDefault = enabled
            persistentStore.user.settings = Object.assign(persistentStore.user.settings, {unitAdder: unitAdderSettings})
        }
    }

    toggleUnitAdderSentMailByDefault() {
        if (persistentStore.user) {
            const unitAdderSettings = persistentStore.user.settings.unitAdder
            unitAdderSettings.mailByDefault = !unitAdderSettings.mailByDefault
            persistentStore.user.settings = Object.assign(persistentStore.user.settings, {unitAdder: unitAdderSettings})
        }
    }

}

decorate(Store, {
    cardTypes: observable,
    company: observable,
    enableSales: observable,
    locale: observable,
    saEvent: observable,
    selectedLang: observable,
    showUpgrade: observable,
    userView: observable,

    emptyStore: action,
    setCardTypes: action,
    setCompany: action,
    setLocale: action,
    setSaEvent: action,
    setSelectedLang: action,
    setShowUpgrade: action,
    setUnitAdderSentMailByDefault: action,
    setUserView: action,
    toggleUnitAdderSentMailByDefault: action,
    // triggerEvent: action,
})

const store = new Store()
export default store
