import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom'
import PlusIcon from 'react-feather/dist/icons/plus-circle'
import ListIcon from 'react-feather/dist/icons/align-justify'
import GridIcon from 'react-feather/dist/icons/grid'
import DownloadIcon from 'react-feather/dist/icons/download'
import Spinner from 'react-feather/dist/icons/settings'
import {observer} from 'mobx-react'
import {customers as customersApi} from '../../api/customers'
import {units as unitsApi} from '../../api/units'
import JSONToCSVConvertor from '../../utils/JSONToCSVConvertor';
import {secondsToTime} from "../../utils/helpers";
import {Alert, Button, ButtonGroup, Col, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import CustomerListView from "./CustomerListView";
import CustomerGridView from "./CustomerGridView";
import CustomerGroups from "../../components/CustomerGroups";
import {useQuery, useQueryCache} from "react-query";
import persistentStore from "../../stores/persistentStore";
import TrialInfoModal from "../../components/TrialInfoModal";
import moment from "moment/moment";
import {ICustomer} from "../../interfaces/ICustomer";
import {ICustomerGroup} from "../../interfaces/ICustomerGroup";
import {IUnit} from "../../interfaces/IUnit";
import ToggleSlider from "../../components/ToggleSlider";
import './CustomerList.scss'
import {toast} from "react-toastify";

const CustomerList = (props: any) => {
    const [showTrialInfo, setShowTrialInfo] = useState(false);
    const queryCache = useQueryCache()
    const [customers, setCustomers] = useState<ICustomer[]>([])
    const [units, setUnits] = useState<IUnit[]>([])
    const [filteredCustomers, setFilteredCustomers] = useState<ICustomer[]>([])
    const {t} = useTranslation()
    const [displayArchived, setDisplayArchived] = useState<boolean>(persistentStore.displayArchived)
    const customerGroupsQuery = useQuery('getCustomerGroups', customersApi.customerGroupsAll)
    const [selectedCustomerPublicIds, setSelectedCustomerPublicIds] = useState<string[]>([])
    const [filteredCustomerGroups, setFilteredCustomerGroups] = useState<ICustomerGroup[]>([])
    const [groupError, setGroupError] = useState('')
    const [groupName, setGroupName] = useState('')
    const [showModal, setShowModal] = useState<boolean>(false)
    const [groupClicked, setGroupClicked] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)
    const customersQuery = useQuery(
        ['getCustomers', persistentStore.user?.id],
        customersApi.getAll,
    )

    useEffect(() => {
        if (customersQuery.data) {
            setCustomers(customersQuery.data)
        }
    }, [customersQuery.data])

    useEffect(() => {
        const filteredCustomersByArchived = customers.filter((customer: ICustomer) => !customer.archived || displayArchived)
        setFilteredCustomers(filteredCustomersByArchived)
    }, [customers, displayArchived])

    useEffect(() => {
        const now = moment()
        const lastSeen = moment(persistentStore.user?.last_seen)
        const lastSeenInMinutesAgo = (now.diff(lastSeen) / 1000) / 60
        const shouldShow = lastSeenInMinutesAgo < 30 && persistentStore.user?.type === 'trial' && persistentStore.showTrialInfo
        if (shouldShow) {
            setShowTrialInfo(true);
            persistentStore.setShowTrialInfo(true)
        }
    }, [])

    const handleCloseTrialInfo = () => {
        setShowTrialInfo(false)
        persistentStore.setShowTrialInfo(false)
    };

    const changeArchivedValue = (showArchivedAsWell: boolean) => {
        setDisplayArchived(showArchivedAsWell)
        persistentStore.setDisplayArchived(showArchivedAsWell)
        queryCache.invalidateQueries('getCustomers')
    }

    const showCustomerListView = () => {
        persistentStore.setCustomerView('list')
    }

    const showCustomerGridView = () => {
        persistentStore.setCustomerView('grid')
    }

    const exportCustomers = () => {
        const useTime = (persistentStore.user?.settings.useType === 'hours')

        // we need to remove some internal data from the customers (like id's)
        const allowList = [
            'name',
            'email',
            'total_time_in_seconds_on_active_cards',
            'strips_booked',
            'strips_remaining',
            'time_bought',
            'time_booked',
            'time_remaining'
        ]

        interface IExportCustomer {
            name: string,
            email: string,
            gekocht: string,
            afgeboekt: string,
            beschikbaar: string,
        }

        const sortOrder = {name: 1, email: 2, gekocht: 3, afgeboekt: 4, beschikbaar: 5}

        const customerDataToExport = customers.map((customer: ICustomer) => {
            const redactedCustomer: IExportCustomer = Object.entries(customer)
                .reduce((customerToExport: IExportCustomer, [propertyName]) => {
                    if (!allowList.includes(propertyName)) {
                        return customerToExport
                    }

                    if (propertyName === 'name') {
                        customerToExport['name'] = customer.name
                    }

                    if (propertyName === 'email') {
                        customerToExport['email'] = customer.email
                    }

                    if (propertyName === 'total_time_in_seconds_on_active_cards' || propertyName === 'total_amount_on_active_cards') {
                        customerToExport['gekocht'] = useTime
                            ? secondsToTime(customer.stats.total_time_in_seconds_on_active_cards).toString()
                            : customer.stats.total_amount_on_active_cards.toString()
                    }

                    if (propertyName === 'total_time_in_seconds_used' || propertyName === 'total_strips_used') {
                        customerToExport['afgeboekt'] = useTime
                            ? secondsToTime(customer.stats?.total_time_in_seconds_used).toString()
                            : customer.stats.total_strips_used.toString()
                    }

                    if (propertyName === 'time_in_seconds_available' || propertyName === 'total_strips_available') {
                        customerToExport['beschikbaar'] = useTime
                            ? secondsToTime(customer.stats.total_time_in_seconds_available).toString()
                            : customer.stats.total_strips_available.toString()
                    }

                    // eleborate way to order the properties, so that they are in the correct order when they're in
                    // the final Excel document (.csv file)
                    return Object.assign(
                        {},
                        ...Object.keys(customerToExport)
                            // @ts-ignore
                            .sort((a: string, b: string) => sortOrder[a] - sortOrder[b])
                            .map((propertyName: string) => {
                                // @ts-ignore
                                return {[propertyName]: customerToExport[propertyName]}
                            })
                    )
                }, {} as IExportCustomer)

            return redactedCustomer
        })

        if (customerDataToExport.length < 1) {
            return;
        }

        JSONToCSVConvertor({
            JSONData: customerDataToExport,
            showLabel: true,
            reportTitle: `strippenkaart-klanten_${moment().format("YYYYMMDD_HHmm")}`
        });
    }

    const fetchLastMonthUnits = () => {
        unitsApi.getLastMonth()
            .then((units) => {
                console.log('Setting the units', units)
                setUnits(units)
            })
    }

    const exportUnits = () => {
        // we need to remove some internal data from the customers (like id's)
        const allowList = [
            'amount',
            'description',
            'created',
            'cards'
        ]

        interface IExportUnit {
            naam: string,
            email: string,
            Amount: number,
            aantal: number,
            beschrijving: string,
            datum: string,
            cards: any
        }

        const sortOrder = {'naam': 1, 'email': 2, 'aantal': 3, 'beschrijving': 4, 'datum': 5}

        const unitsDataToExport = units.map((unit: IUnit) => {
            const redactedUnit: IExportUnit = Object.entries(unit)
                .reduce((unitToExport: IExportUnit, [propertyName]) => {
                    if (!allowList.includes(propertyName)) {
                        return unitToExport
                    }

                    if (propertyName === 'cards' && unit.card) {
                        unitToExport['naam'] = unit.card.customer?.name || ''
                        unitToExport['email'] = unit.card.customer?.email || ''
                    }

                    if (propertyName === 'description') {
                        unitToExport['beschrijving'] = unit.description
                    }

                    if (propertyName === 'amount') {
                        unitToExport['aantal'] = unit.amount.strips
                    }

                    if (propertyName === 'created') {
                        unitToExport['datum'] = moment(unit.created_at).format('yyyy-MM-DD h:mm')
                    }

                    // eleborate way to order the properties, so that they are in the correct order when they're in
                    // the final Excel document (.csv file)
                    return Object.assign(
                        {},
                        ...Object.keys(unitToExport)
                            // @ts-ignore
                            .sort((a: string, b: string) => sortOrder[a] - sortOrder[b])
                            .map((propertyName: string) => {
                                // @ts-ignore
                                return {[propertyName]: unitToExport[propertyName]}
                            })
                    )
                }, {} as IExportUnit)

            return redactedUnit
        })

        if (unitsDataToExport.length < 1) {
            toast.warning(t('There are no strips booked in the last month'))
            console.log('No data to export')
            return;
        }

        JSONToCSVConvertor({
            JSONData: unitsDataToExport,
            showLabel: true,
            reportTitle: `strippenkaart-units_${moment().format("YYYYMMDD_HHmm")}`
        });
    }

    const addGroupToSelection = (group: ICustomerGroup) => {
        const updatedSelectedCustomers = [...selectedCustomerPublicIds, ...group.customers]
        setSelectedCustomerPublicIds(updatedSelectedCustomers)
    }

    /**
     * Add a new group
     * @param e
     */
    const handleSubmitGroup = (e: any) => {
        e.preventDefault()

        if (groupName === '') {
            setGroupError(t('Enter group name'))
            return
        }

        setLoading(true)

        customersApi.addCustomerGroup(groupName, selectedCustomerPublicIds)
            .then((response) => {
                setLoading(false)

                if (response.data.success) {
                    toast.success(t('Group created'))
                    queryCache.invalidateQueries('getCustomerGroups')
                    setShowModal(false)
                } else {
                    toast.error(response.data.errors.title)
                }
            })
    }

    const removeGroupFromSelection = (group: ICustomerGroup) => {
        const updatedSelectedCustomers: string[] = selectedCustomerPublicIds.filter((selectedUserPublicId: string) => {
            return !group.customers.includes(selectedUserPublicId)
        })
        setSelectedCustomerPublicIds(updatedSelectedCustomers)
    }

    const addCustomerToSelection = (customerPublicId: string) => {
        const updatedSelectedCustomers = [...selectedCustomerPublicIds, customerPublicId]
        setSelectedCustomerPublicIds(updatedSelectedCustomers)
    }

    const removeCustomerFromSelection = (customerPublicId: string) => {
        const updatedCustomerPublicIds: string[] = selectedCustomerPublicIds.filter((selectedCustomerPublicId: string) => {
            return selectedCustomerPublicId !== customerPublicId
        });
        setSelectedCustomerPublicIds(updatedCustomerPublicIds)
    }

    useEffect(() => {
        setFilteredCustomerGroups(customerGroupsQuery.data)
    }, [customerGroupsQuery.data])

    return (
        <div className='customer-list mt-1 mr-2'>
            <div className='d-flex'>
                {props.error && <div>Error: {props.error.message}</div>}
                <div className='ml-lg-0'>
                    <h2 className="customer-header p-0">
                        {filteredCustomers.length === 1 && (
                            `1 ${t('Customer')}`
                        )}

                        {filteredCustomers.length !== 1 && (
                            `${filteredCustomers.length} ${t('Customers')}`
                        )}
                        <Link
                            id='customer-add'
                            to={{pathname: '/customers/add'}}
                            className="align-items-center text-muted hidden ml-2"
                        >
                            <PlusIcon className='customer-add-icon hidden-xs'/>
                        </Link>
                        {customersQuery.isLoading && '&nbsp;' && <Spinner className="spinner"/>}
                    </h2>
                </div>

                <div style={{marginLeft: 'auto'}}>
                    <ButtonGroup size='sm'>
                        <div className="dropdown mr-2">
                            <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                onClick={() => {fetchLastMonthUnits()}}
                            >
                                <DownloadIcon size={20}/>
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <span onClick={exportUnits} className='dropdown-item' style={{cursor: 'pointer'}}>
                                    {t('Units')}
                                </span>
                                <span onClick={exportCustomers} className='dropdown-item' style={{cursor: 'pointer'}}>
                                    {t('Customers')}
                                </span>
                            </div>
                        </div>

                        <Button
                            variant="secondary"
                            className={`${persistentStore.customerView === 'grid' && 'active'} grid-view`}
                            onClick={showCustomerGridView}
                            title="Grid"
                            id='customer-grid-view'
                        >
                            <GridIcon size={20}/>
                        </Button>

                        <Button
                            variant="secondary"
                            className={`${persistentStore.customerView === 'list' && 'active'} list-view`}
                            onClick={showCustomerListView}
                            title="List"
                            id='customer-list-view'
                        >
                            <ListIcon/>
                        </Button>
                    </ButtonGroup>
                </div>
            </div>

            <Row className="col-12">
                <Col xs={12}>
                    <ToggleSlider
                        size='md'
                        type='round'
                        id='show-archived-customers'
                        checked={displayArchived}
                        onChange={(checked: boolean) => {
                            changeArchivedValue(checked)
                        }}
                        text={t('Show archived customers')}
                        className='mt-5 mb-2 ml-3'
                    />
                </Col>
            </Row>

            {(filteredCustomers.length === 0 && !customersQuery.isLoading) && (
                <Alert variant='warning' className='col-12'>
                    {t('You did not create any customers')}. {t('Click on the plus-icon above to create your first customer')}
                </Alert>
            )}

            <div className='d-flex flex-column flex-column-reverse flex-md-row'>
                <div className="d-flex m-3 flex-fill">
                    {persistentStore.customerView === 'grid' && (
                        <CustomerGridView
                            user={props.user}
                            customers={filteredCustomers}
                            loading={customersQuery.isLoading}
                            addCustomer={addCustomerToSelection}
                            removeCustomerFromSelection={removeCustomerFromSelection}
                            selectedCustomers={selectedCustomerPublicIds}
                            groupClicked={groupClicked}
                        />
                    )}

                    {persistentStore.customerView === 'list' && (
                        <CustomerListView
                            loading={customersQuery.isLoading}
                            customers={filteredCustomers}
                            addCustomer={addCustomerToSelection}
                            removeCustomerFromSelection={removeCustomerFromSelection}
                            selectedCustomers={selectedCustomerPublicIds}
                            groupClicked={groupClicked}
                        />
                    )}
                </div>

                <div className='m-sm-3 m-md-1 mb-2'>
                    <CustomerGroups
                        addUsersInGroupToSelection={addGroupToSelection}
                        customerGroups={filteredCustomerGroups}
                        groupError={groupError}
                        handleSubmitGroup={handleSubmitGroup}
                        loading={loading || customerGroupsQuery.isLoading}
                        removeUsersInGroupFromSelection={removeGroupFromSelection}
                        selectedCustomers={selectedCustomerPublicIds}
                        setGroupName={setGroupName}
                        setShowModal={setShowModal}
                        show={showModal}
                        onGroupClicked = {(isClicked: boolean) => setGroupClicked(isClicked)}
                    />
                </div>
            </div>

            {showTrialInfo && (
                <TrialInfoModal
                    onHide={handleCloseTrialInfo}
                />
            )}
        </div>
    )
}

export default observer(CustomerList)
