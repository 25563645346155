import React, {useState} from 'react'
import moment from 'moment'
import Hash from 'react-feather/dist/icons/hash'
import jQuery from 'jquery'
import {observer} from 'mobx-react'
import {cards} from '../../api/cards'
import {toast} from 'react-toastify'
import {Button, Row, Col} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import DatePicker from "react-datepicker";
import {stripnameTimePlural} from "../../utils/stripname";
import {ICustomer} from "../../interfaces/ICustomer";
import {ICard} from "../../interfaces/ICard";
import useTime from "../../utils/useTime";

type CardAddProps = {
    customer: ICustomer,
    invalidateCache: () => void,
    toggleaddcard: () => void,
}

const CardAdd = (props: CardAddProps) => {
    const {customer} = props
    const [amount, setAmount] = useState(10)
    const [description, setDescription] = useState('')
    const [dueDateUser, setDueDateUser] = useState<Date | undefined>()
    const [error, setError] = useState<{ message: string }>()
    const [loading, setLoading] = useState(false)
    const startLoading = () => setLoading(true)
    const stopLoading = () => setLoading(false)
    const [hasCustomValidity, setHasCustomValidity] = useState<boolean>(false)
    const [durationQuantity, setDurationQuantity] = useState<number>(1)
    const [durationUnit, setDurationUnit] = useState<string>('years')
    const {t} = useTranslation()

    /**
     * Empty the error message and make sure the error-div is not hidden
     * anymore
     */
    const resetError = () => {
        setError({message: ''})

        // ensure the errorPanel is visible
        jQuery('div.errorPanel > div').slideDown()
    }

    const handleAmountChange = (event: any) => {
        const elId = event.target.id

        // on empty inputfield
        if (event.target.value === '') {
            setAmount(0)
            return false
        }

        // parse the value to an integer
        const amount = parseInt(event.target.value, 10)

        // check whether it's a valid integer
        if (isNaN(amount)) {
            console.log('invalid character typed')
            return false
        }

        document.getElementById(elId)?.classList.remove('error')

        setAmount(amount)
    }

    // when the form is submitted
    const handleSubmit = (event: any) => {
        event.preventDefault()

        // ensure the errorPanel is visible
        resetError()

        // validate
        const card: ICard = {} as ICard
        card.customer_id = customer.id
        card.amount = amount
        card.description = description
        if (card.amount === undefined || card.amount < 1) {
            setError({message: 'Geef 1 of meer strippen op.'})
            return
        }

        // validate
        if (isNaN(card.amount)) {
            const elAmount = document.getElementById('inputAmount')
            elAmount?.classList.add('error')
            return
        }

        // when duedate is set,
        card.duedate = dueDateUser === undefined ? '' : moment(dueDateUser).format('Y-M-D HH:mm:ss')
        startLoading()

        cards.add(card)
            .then((addedCard) => {
                stopLoading()
                toast.success('Strippenkaart toegevoegd')

                // refetch the customers to get the changes from the server
                props.invalidateCache()

                props.toggleaddcard()
            })
            .catch((error) => {
                console.log('dit is de error: ', error)
            })
            .finally(() => setLoading(false))
    }

    const slideUp = (event: any) => {
        jQuery(event.target).slideUp()
    }

    const setCustomDurationUnit = (unit: string) => {
        setDurationUnit(unit)
        handleGeldigheidsduur(durationQuantity+" "+unit)
    }

    const setCustomDurationAmount = (amount: number) => {
        setDurationQuantity(amount)
        handleGeldigheidsduur(amount+" "+durationUnit)
    }

    const handleAmountDuration = (amountUnit: any) => {
        setHasCustomValidity((amountUnit === 'Other'))
        if(amountUnit === 'Other'){
            amountUnit = durationQuantity+" "+durationUnit
        }
        handleGeldigheidsduur(amountUnit)
    }

    const handleGeldigheidsduur = (amountUnit: any) => {
        const options = amountUnit.split(' ')
        let duedate: Date | undefined

        if (options[0] === 'onbeperkt') {
            duedate = undefined
        }else{
            if (options.length === 1) {
                duedate = undefined
            } else if (options.length === 2) {
                const amount= options[0]
                const unit = options[1]
                // alert(amount+" ==> "+unit)
                duedate = moment().add(amount, unit).toDate()
            }
        }

        setDueDateUser(duedate)

    }

    return (
        <Row id="CardAdd" className={'CardAdd block-row unit-adder ml-0 col-sm-12'}>
            <div className="info-block-header col-12">
                <h2>
                    <div style={{textTransform: 'capitalize'}}>
                        {useTime ? 'Strippenkaart toevoegen' : stripnameTimePlural() + 'kaart toevoegen'}
                    </div>
                </h2>
            </div>

            <div className="info-block col-12">
                <form onSubmit={handleSubmit}>
                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label htmlFor="inputAmount">Aantal {useTime ? 'strippen' : stripnameTimePlural()}</label>
                            <div className="row">
                                <div className="input-group-prepend col-3 mr-0">
                                    <div className="input-group-text">
                                        <Hash/>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    className="form-control col-5"
                                    name="amount"
                                    id="inputAmount"
                                    onChange={(event) => handleAmountChange(event)}
                                    placeholder="0"
                                    pattern="\d*"
                                    value={amount}
                                    title={`Aantal ${stripnameTimePlural()} om toe te voegen`}
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div className="col">
                            <label htmlFor="inputDuedate">Geldigheidsduur van de {useTime ? 'strippen' : stripnameTimePlural()}</label>
                            <select
                                id="inputDueDate"
                                className="form-control"
                                name="geldigheidsduur"
                                onChange={(e) => handleAmountDuration(e.target.value)}
                            >
                                <option value="0">onbeperkt</option>
                                <option value="3 weeks">3 weken</option>
                                <option value="12 weeks">12 weken</option>
                                <option value="1 months">1 maand</option>
                                <option value="3 months">3 maanden</option>
                                <option value="6 months">6 maanden</option>
                                <option value="1 years">1 jaar</option>
                                <option value="Other">{t('other')}...</option>
                            </select>
                        </div>

                        {hasCustomValidity && (
                            <div className="form-row">
                                <Col sm={12}>
                                    <label htmlFor="inputCustomDuration">{t('Custom duration')}</label>
                                </Col>
                                <Col>
                                    <input
                                        type="number"
                                        min={1}
                                        step='any'
                                        className="form-control"
                                        id="inputCustomDuration"
                                        placeholder={t('Duration')}
                                        value={durationQuantity}
                                        onChange={(e) => setCustomDurationAmount(parseInt(e.target.value))}
                                        name="customDurtion"
                                        required
                                    />
                                </Col>

                                <Col>
                                    <select
                                        id="inputValidityType"
                                        className="form-control"
                                        name="geldigheidsduur"
                                        value={durationUnit}
                                        onChange={(event) => setCustomDurationUnit(event.target.value)}
                                    >
                                        <option value="days">{t('days')}</option>
                                        <option value="weeks">{t('weeks')}</option>
                                        <option value="months">{t('months')}</option>
                                        <option value="years">{t('year')}</option>
                                    </select>
                                </Col>
                            </div>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputDuedate">Verloopdatum (laat leeg voor geen verloopdatum)</label>
                        <DatePicker
                            selected={dueDateUser}
                            onChange={(date: Date) => setDueDateUser(date)}
                            dateFormat="dd-MM-yyyy"
                            className="form-control"
                            // showTimeSelect
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="dd-mm-yyyy"
                            locale="nl"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputDescription">{t('Description')}</label>
                        <textarea
                            className="form-control"
                            name="description"
                            id="inputDescription"
                            onChange={(event) => setDescription(event.target.value)}
                            placeholder={t("Description")}
                            value={description}
                        />
                    </div>

                    <div className="form-row">
                        <button
                            id='cancel-add-card'
                            type="button"
                            className="btn btn-link"
                            onClick={props.toggleaddcard}
                        >
                            {t('Cancel')}
                        </button>
                        &nbsp;
                        <Button
                            id='add-card'
                            type="submit"
                            size='lg'
                            className="btn btn-primary"
                            disabled={loading}
                        >
                            Strippenkaart Toevoegen
                        </Button>
                    </div>

                </form>

                <div className='errorPanel'>
                    {(error && error.message) && (
                        <div
                            className="alert alert-warning"
                            role="alert"
                            onClick={slideUp}
                        >
                            {error.message}
                        </div>
                    )}
                </div>
            </div>
        </Row>
    )
}

export default observer(CardAdd)
