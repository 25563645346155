import React, {ChangeEvent, useEffect, useState} from 'react';
import Card from "react-bootstrap/Card";
import {useTranslation} from "react-i18next";
import ToggleSlider from "../../components/ToggleSlider";
import persistentStore from "../../stores/persistentStore";
import {IUserSettings} from "../../interfaces/IUserSettings";
import {users} from "../../api/users";
import {toast} from "react-toastify";
import {Alert, Button} from "react-bootstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useQuery} from "react-query";

const CardTypeSettings = () => {
    const {t} = useTranslation()
    const settings = persistentStore.user?.settings || {} as IUserSettings
    const [showSettings, setShowSettings] = useState(false)
    const [saleStatus, setSaleStatus] = useState(persistentStore.user?.settings?.saleStatus)
    const [saleSlug, setSaleSlug] = useState(persistentStore.user?.sale_slug || '')
    const [salespageUrl, setSalespageUrl] = useState('')
    const [salespageTitle, setSalespageTitle] = useState<string>(settings.salespageTitle)
    const [salespageDescription, setSalespageDescription] = useState<string>(settings.salespageDescription)
    const [saleSlugUnique, setSaleSlugUnique] = useState(true)
    const [saleSlugChanged, setSaleSlugChanged] = useState(false)
    const [disableSave, setDisableSave] = useState<boolean>(false)
    const [disableCancel, setDisableCancel] = useState<boolean>(false)

    // fire query everytime 'slug' changes (unless it's empty)
    useQuery(
        ['saleSlugUnique', saleSlug],
        () => users.isSaleSlugUnique(saleSlug),
        {
            // cacheTime: 0,
            enabled: saleSlug !== '' && saleSlug !== persistentStore.user?.sale_slug,
            onSettled: (isUnique) => setSaleSlugUnique(isUnique)
        }
    )

    useEffect(() => {
        setSaleSlugChanged(saleSlug !== persistentStore.user?.sale_slug)
    }, [saleSlug])

    useEffect(() => {
        setDisableSave(saleSlugChanged)
        setDisableCancel(saleSlugChanged)

        if (salespageTitle !== persistentStore.user?.settings.salespageTitle) {
            setDisableSave(true)
            setDisableCancel(true)
        }

        if (salespageDescription !== persistentStore.user?.settings.salespageDescription) {
            setDisableSave(true)
            setDisableCancel(true)
        }

    }, [saleSlugChanged, salespageTitle, salespageDescription])

    useEffect(() => {
        const url = window.location.origin + '/order/' + saleSlug
        setSalespageUrl(url)
    }, [saleSlug])

    const saveSalesPageSettings = () => {
        // update the salespage settings
        saveSalesTitleDesc()

        // do nothing when the slug is not changed
        if (!saleSlugChanged) {
            return
        }

        users.saveNewSaleSlug(saleSlug)
            .then(() => {
                // update the sale_slug in localstorage as well
                const userWithUpdatedSaleSlug = Object.assign({}, persistentStore.user, {sale_slug: saleSlug})
                persistentStore.setUser(userWithUpdatedSaleSlug)
                setSaleSlugChanged(false)
                toast.success(t('Successfully changed the salespage url'))
            })
            .catch((error) => {
                console.error(error.response.data.errors)
                toast.error(error.response?.data?.errors?.detail)
            })
    }

    const changeSaleSlug = (newSlug: string) => {
        // ensure lowercase, no spaces, only ascii
        const sluggifiedSlug = newSlug.replaceAll(' ', '-')

        // ensure unique
        setSaleSlug(sluggifiedSlug)
    }

    const changeStatus = (newStatus: "live" | "test") => {
        const liveKeyAvailable = (settings.mollieLiveKey !== undefined && settings.mollieLiveKey !== '')
        const testKeyAvailable = (settings.mollieTestKey !== undefined && settings.mollieTestKey !== '')

        if (!['live', 'test'].includes(newStatus)) {
            toast.error('Verkeerde status voor de verkoop! Je kunt alleen "test" of "live" status hebben')
            return
        }

        // only allowed if the appropriate keys are in place
        if (newStatus === 'live' && !liveKeyAvailable) {
            toast.error(t('You need to provide the Mollie Live API key to enable live mode'))
            return false
        }

        if (newStatus === 'test' && !testKeyAvailable) {
            toast.error(t('You need to provide the Mollie Test API key to enable test mode'))
            return false
        }

        setSaleStatus(newStatus)

        if (!persistentStore.user) {
            return
        }

        const updatedSettings: IUserSettings = Object.assign(
            persistentStore.user?.settings,
            {saleStatus: newStatus}
        )

        users.updateSettings(updatedSettings, persistentStore.user?.id)
            .then(response => {
                if (response.success) {
                    const message = newStatus === 'live'
                        ? t('Payments are live')
                        : t('Payments are in test mode')

                    toast.success(message)
                } else {
                    toast.error(t('Settings could not be updated'))
                }
            })
    }

    const changeShowIncVat = (newStatus: boolean) => {
        if (!persistentStore.user) {
            return
        }

        const updatedSettings: IUserSettings = Object.assign(
            persistentStore.user?.settings,
            {showIncVat: newStatus}
        )

        users.updateSettings(updatedSettings, persistentStore.user?.id)
            .then(response => {
                if (response.success) {
                    const message = newStatus
                        ? t('Showing prices including VAT')
                        : t('Showing prices excluding VAT')

                    toast.success(message)
                } else {
                    toast.error(t('Settings could not be updated'))
                }
            })
    }

    const changeVatPercentage = (newPercentage: string) => {
        if (!persistentStore.user) {
            return
        }

        const intPercentage = parseInt(newPercentage, 10)

        if (![0, 9, 21].includes(intPercentage)) {
            toast.error(t('Invalid value for VAT percentage provided'))
            return
        }

        const floatPercentage = (intPercentage / 100).toFixed(2)

        const updatedSettings: IUserSettings = Object.assign(
            persistentStore.user?.settings,
            {vatPercentage: floatPercentage}
        )

        users.updateSettings(updatedSettings, persistentStore.user?.id)
            .then(response => {
                if (response.success) {
                    const message = t('VAT percentage updated')
                    toast.success(message)
                } else {
                    toast.error(t('Settings could not be updated'))
                }
            })
    }

    const changePaymentWindowOption = (shouldOpenInNewWindow: boolean) => {
        if (!persistentStore.user) {
            return
        }

        const updatedSettings: IUserSettings = Object.assign(
            persistentStore.user?.settings,
            {paymentsInNewWindow: shouldOpenInNewWindow}
        )

        users.updateSettings(updatedSettings, persistentStore.user?.id)
            .then(response => {
                if (response.success) {
                    const message = shouldOpenInNewWindow
                        ? t('Payments will now open in a new window')
                        : t('Payments will now open in the same window')
                    toast.success(message)
                } else {
                    toast.error(t('Settings could not be updated'))
                }
            })
    }

    const hideSalespage = (shouldHide: boolean) => {
        if (!persistentStore.user) {
            return
        }

        const updatedSettings: IUserSettings = Object.assign(
            persistentStore.user?.settings,
            {hideSalespage: shouldHide}
        )

        users.updateSettings(updatedSettings, persistentStore.user?.id)
            .then(response => {
                if (response.success) {
                    const message = shouldHide
                        ? 'De verkooppagina toont niet alle beschikbare strippenkaarten'
                        : 'De verkooppagina toont wel alle beschikbare strippenkaarten'
                    toast.success(message)
                } else {
                    toast.error(t('Settings could not be updated'))
                }
            })
    }

    const changeDisplayBuyButton = (displayBuyButton: boolean) => {
        if (!persistentStore.user) {
            return
        }

        const updatedSettings: IUserSettings = Object.assign(
            persistentStore.user?.settings,
            {display_buy_button: displayBuyButton}
        )

        users.updateSettings(updatedSettings, persistentStore.user?.id)
            .then(response => {
                if (response.success) {
                    const message = displayBuyButton
                        ? t('The buy button is enabled on the customer page')
                        : t('The buy button is disabled on the customer page')
                    toast.success(message)
                } else {
                    toast.error(t('Settings could not be updated'))
                }
            })
    }

    const saveSalesTitleDesc = () => {

        if (!persistentStore.user) {
            return
        }

        const updatedSettings: IUserSettings = Object.assign(
            persistentStore.user?.settings,
            {
                salespageTitle: salespageTitle,
                salespageDescription: salespageDescription
            }
        )

        users.updateSettings(updatedSettings, persistentStore.user?.id)
            .then(response => {
                if (response.success) {
                    const message = t('Sales page settings updated')
                    toast.success(message)
                } else {
                    toast.error(t('Settings could not be updated'))
                }
            })
    }

    const toggleSettings = () => {
        const toggledState = !showSettings
        setShowSettings(toggledState)
    }

    const resetSalesPageSettings = () => {
        setSaleSlug(persistentStore.user?.sale_slug || '')
        setSalespageTitle(persistentStore.user?.settings.salespageTitle || '')
        setSalespageDescription(persistentStore.user?.settings.salespageDescription || '')
    }

    return (
        <Card className='mt-4'>
            <Card.Header id="cardtype-settings" onClick={toggleSettings} style={{cursor: 'pointer'}}>
                {!showSettings && <i className='fa fa-angle-right'/>}
                {showSettings && <i className='fa fa-angle-down'/>}
                &nbsp;
                {t('Settings')}
            </Card.Header>

            {showSettings && (
                <Card.Body id='settings-body'>
                    <div className='flex'>
                        <div>{t('Payment status') + ': '} <strong>{settings.saleStatus}</strong></div>
                        <div>
                            <small>{t('When set to "test", no real payments are made')}</small>
                        </div>
                        <ToggleSlider
                            id='sale-status'
                            type='round'
                            checked={saleStatus === 'live'}
                            onChange={(checked: boolean) => {
                                changeStatus(checked ? 'live' : 'test')
                            }}
                        />
                    </div>

                    <div className='flex-row mt-4'>
                        <div>{t('Show prices') + ': '}<strong>{(settings.showIncVat ? t('including VAT') : t('exluding VAT'))}</strong></div>
                        <div>
                            <small>{t('When your users are consumers, they probably want to see prices including VAT on the salespage')}</small>
                        </div>
                        <ToggleSlider
                            id='show-inc-vat'
                            type={"round"}
                            checked={settings.showIncVat}
                            onChange={changeShowIncVat}
                        />
                    </div>

                    <div className='flex mt-4'>
                        <div>{t('Display buy button on customer page')}</div>
                        <div>
                            <small>
                                {settings.display_buy_button && t('A buy button is shown on the customer pager')}
                                {!settings.display_buy_button && t('No buy button is shown on the customer page')}
                            </small>
                        </div>
                        <ToggleSlider
                            id='display-buy-button'
                            type='round'
                            checked={settings.display_buy_button}
                            onChange={(checked: boolean) => {
                                changeDisplayBuyButton(checked)
                            }}
                        />
                    </div>

                    <div className='flex mt-4'>
                        <div>
                            {t('Payments are done in') + ': '}
                            <strong>
                                {(settings.paymentsInNewWindow ? t('a new window, seperate from the salespage') : t('the same window as the salespage'))}
                            </strong>
                        </div>
                        <div>
                            <small>
                                {t('Leave this off or on "in the same window" unless you use the salespage in an iframe on your website')}
                            </small>
                        </div>
                        <ToggleSlider
                            id='payment-in-new-window'
                            type='round'
                            checked={settings.paymentsInNewWindow}
                            onChange={(checked: boolean) => {
                                changePaymentWindowOption(checked)
                            }}
                        />
                    </div>

                    <div className="form-group mt-4">
                        <label htmlFor="selectVatPercentage">{t('VAT percentage')}</label>
                        <div>
                            <small>{t('The amount of VAT is applicable to your business')}</small>
                        </div>
                        <select
                            className="form-control"
                            id="selectVatPercentage"
                            defaultValue={settings.vatPercentage * 100}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) => changeVatPercentage(event.target.value)}
                        >
                            <option value="0">0%</option>
                            <option value="9">9%</option>
                            <option value="21">21%</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <h4>{t('Salespage url')}</h4>
                        <label htmlFor="inputName">
                            {t('You can change the last part of your salespage url')}. <br/>
                            {t('This part has to be unique and can contain only letters, numbers and hyphens')}
                        </label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">/order/</div>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                id="inputSlug"
                                aria-describedby="saleSlugHelp"
                                placeholder={t('Your sale slug')}
                                value={saleSlug}
                                onChange={(e) => changeSaleSlug(e.target.value)}
                                name="email"
                                autoComplete="off"
                            />
                        </div>

                        {!saleSlugUnique && saleSlugChanged && <Alert variant={"danger"}>{t('Slug is not unique!')}</Alert>}

                        {saleSlugChanged && (
                            <div className='mb-1'>
                                <strong>{t('New url will be')}:&nbsp;</strong>
                                {salespageUrl}
                            </div>
                        )}

                        <div className='mb-2'>
                            <strong>{t('Current url')}:&nbsp;</strong>
                            {window.location.origin + '/order/' + (persistentStore.user?.slug || '')}
                        </div>

                        <p>
                            <strong>{t('Note')}:</strong>&nbsp;
                            {t('When you change the url, the old one does not work anymore!')}<br/>
                            {t('Be sure to update the link everywhere you used it (your website, customer e-mails, etc.)')}
                        </p>
                    </div>

                    <div className='flex mt-4'>
                        <h4>{t('Hide salespage')}</h4>
                        <div>
                            {t('Hide the salespage when you don\'t want to show all your cards on one page for everyone to see')}.
                        </div>
                        <div>
                            <small>
                                {settings.hideSalespage && t('Only individual cards can be bought')}
                                {!settings.hideSalespage && t('Salespage with all cards is available')}
                            </small>
                        </div>
                            <ToggleSlider
                                className='hide-salespage'
                                id='hide-salespage'
                                type='round'
                                checked={settings.hideSalespage}
                                onChange={(checked: boolean) => {
                                    hideSalespage(checked)
                                }}
                            />
                    </div>

                    <hr/>

                    <h4>{t('Salespage')}</h4>
                    <div className="form-group">
                        <label htmlFor="salespageTitle">{t('Title')}</label>
                        <input
                            type="text"
                            className="form-control"
                            id="salespageTitle"
                            aria-describedby="salespageTitle"
                            placeholder={t('Sales Page Title')}
                            value={salespageTitle}
                            onChange={(e) => setSalespageTitle(e.target.value)}
                            name="salesPageTitle"
                            autoComplete="off"
                        />
                    </div>

                    <div className="form-group mb-5">
                        <label htmlFor="salespageTitle">{t('Description')}</label>
                        <ReactQuill
                            style={{height: "200px"}}
                            theme="snow"
                            value={salespageDescription}
                            onChange={setSalespageDescription}
                            placeholder={t('Sales Page Description')}
                        />
                    </div>
                    <Button
                        disabled={!disableCancel}
                        className='ml-1'
                        onClick={() => resetSalesPageSettings()}
                        variant='outline-secondary'
                    >
                        {t('Reset')}
                    </Button>

                    <Button
                        disabled={!disableSave}
                        className='ml-1'
                        variant={disableSave ? 'primary' : 'outline-primary'}
                        onClick={saveSalesPageSettings}
                    >
                        {t('Save')}
                    </Button>
                </Card.Body>
            )}
        </Card>
    )
}

export default CardTypeSettings;
