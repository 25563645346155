import React, {useEffect, useState} from 'react'
import jQuery from 'jquery'
import {observer} from 'mobx-react'
import {units} from '../../api/units'
import {customers as customersApi} from '../../api/customers'
import {toast} from 'react-toastify'
import {Alert, Col, Row, Spinner, Button, Jumbotron} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import persistentStore from "../../stores/persistentStore";
import CustomersDataTable from "./CustomersDataTable";
import CustomerGroups from "../../components/CustomerGroups";
import {useQuery, useQueryCache} from "react-query";
import {ICustomerGroup} from "../../interfaces/ICustomerGroup";
import {ICustomer} from "../../interfaces/ICustomer";
import ToggleSlider from "../../components/ToggleSlider";
import {timeToSeconds} from "../../utils/helpers";

const ClassPage = () => {
    const useTime = persistentStore.user?.settings.useType === 'hours'
    const initialAmount = useTime ? '01:00' : 1
    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState(initialAmount)
    const [notifyByEmail, setNotifyByEmail] = useState(persistentStore.user?.settings.unitAdder.mailByDefault)
    const [description, setDescription] = useState('')
    const [date, setDate] = useState<any>(new Date())
    const [error, setError] = useState('')
    const {t} = useTranslation()
    const [selectedCustomerPublicIds, setSelectedCustomerPublicIds] = useState<string[]>([])
    const queryCache = useQueryCache()
    const [customerQuantities, setCustomerQuantities] = useState<any>({})
    const customersQuery = useQuery('getCustomers', customersApi.getAll, {
        onSuccess: (data: any) => {

            if(Object.keys(customerQuantities).length === 0){
                let customerAmount:any = {}
                for(let customer of data){
                    customerAmount[customer.public_id] = amount
                }

                setCustomerQuantities(customerAmount)
            }
        }
    })
    const customerGroupsQuery = useQuery('getCustomerGroups', customersApi.customerGroupsAll)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [showArchivedCustomers, setShowArchivedCustomers] = useState<boolean>(false)
    const [filteredCustomerGroups, setFilteredCustomerGroups] = useState<ICustomerGroup[]>([])
    const [groupError, setGroupError] = useState('')
    const [groupName, setGroupName] = useState('')

    useEffect(() => {
        setFilteredCustomerGroups(customerGroupsQuery.data)
    }, [customerGroupsQuery.data])

    const addCustomerToSelection = (customerPublicId: string) => {
        const updatedSelectedCustomers = [...selectedCustomerPublicIds, customerPublicId]
        setSelectedCustomerPublicIds(updatedSelectedCustomers)
    }

    /**
     * When the group is clicked, set all customers in that group as selected
     * @param group
     */
    const addGroupToSelection = (group: ICustomerGroup) => {
        const updatedSelectedCustomers = [...selectedCustomerPublicIds, ...group.customers]
        setSelectedCustomerPublicIds(updatedSelectedCustomers)
    }

    const removeGroupFromSelection = (group: ICustomerGroup) => {
        const updatedSelectedCustomers: string[] = selectedCustomerPublicIds.filter((selectedUserPublicId: string) => {
            return !group.customers.includes(selectedUserPublicId)
        })
        setSelectedCustomerPublicIds(updatedSelectedCustomers)
    }

    const removeCustomerFromSelection = (customerPublicId: string) => {
        const updatedCustomerPublicIds: string[] = selectedCustomerPublicIds.filter((selectedCustomerPublicId: string) => {
            return selectedCustomerPublicId !== customerPublicId
        });
        setSelectedCustomerPublicIds(updatedCustomerPublicIds)
    }

    const updateCustomerAmount = (customerPublicId: any, selectedAmount: any) => {
        let customerQuantitesNew:any = {}
        customerQuantitesNew[customerPublicId] = selectedAmount
        setCustomerQuantities({...customerQuantities, ...customerQuantitesNew})
    }

    const handleAmountChange = (newAmount: any) => {

        setAmount(newAmount);
        let customerAmount:any = {}

        for(let customer of customersQuery.data){
            customerAmount[customer.public_id] = newAmount
        }

        setCustomerQuantities(customerAmount)
    }

    const isValidAmount = (amountToValidate: any) => {
        if (useTime) {
            return true
        }

        // must be numeric
        if (isNaN(amountToValidate)) {
            return false
        }

        return amountToValidate >= 1
    }

    /**
     * Empty the error message and make sure the error-div is not hidden
     * anymore
     */
    const resetError = () => {
        setError('')

        // ensure the errorPanel is visible
        jQuery('div.errorPanel > div').slideDown()
    }

    const removeArchivedCustomersFromSelection = (customers: ICustomer[]): ICustomer[] => {
        const selectedActiveCustomers = customers
            // get the ones that are not archived
            .filter((customer: ICustomer) => !customer.archived)

        console.log('selectedActiveCustomers', selectedActiveCustomers)
        return selectedActiveCustomers
    }

    const removeCustomersWithInsufficientCredit = (customers: ICustomer[]) => {
        const selectedCustomersWithSufficientCredit = customers
            .filter((customer: ICustomer) => {
                    const amountToBeBookedOff = useTime
                        ? timeToSeconds(String(amount))
                        : customerQuantities[customer.public_id]

                    console.log('amountToBeBookedOff', amountToBeBookedOff)

                    const userHasSufficient =  useTime
                        ? customer.stats.total_time_in_seconds_available >= amountToBeBookedOff
                        : customer.stats.total_strips_available >= amountToBeBookedOff

                console.log(`user ${customer.name} ${userHasSufficient ? 'HAS' : 'HAS NOT'} sufficient funds (${customer.stats.total_time_in_seconds_available})`)
                return userHasSufficient
            })

        console.log('selectedCustomersWithSufficientCredit', selectedCustomersWithSufficientCredit)
        return selectedCustomersWithSufficientCredit
    }

    // The function which is called when submitting a work-entry ( aka adding
    // a unit to a card) when the form is submitted
    const addUnit = (event: any) => {
        event.preventDefault()

        // only do something when a valid value has been provided
        if (!isValidAmount(amount)) {
            setError('Geef 1 of meer strippen op.')
            return
        }

        const selectedCustomers = customersQuery.data?.filter((customer: ICustomer) => {
            return selectedCustomerPublicIds.includes(customer.public_id)
        })

        const selectedCustomersWithoutArchived = removeArchivedCustomersFromSelection(selectedCustomers)
        const selectedCustomersWithSufficientCredit = removeCustomersWithInsufficientCredit(selectedCustomersWithoutArchived)
        const publicIdsToBookOffOf = selectedCustomersWithSufficientCredit.map((customer: ICustomer) => customer.public_id)

        console.log('selectedCustomerPublicIds', publicIdsToBookOffOf)

        resetError()

        if (selectedCustomersWithSufficientCredit.length === 0) {
            setError('Selecteer minstens 1 klant uit de lijst hieronder')
            return
        }

        // get the quantities that are selected for each customer (which may be different)
        const selectedCustomerAmount:any = {}
        for(let customerPublicID of Object.keys(customerQuantities)){
            if(publicIdsToBookOffOf.includes(customerPublicID) ){
                selectedCustomerAmount[customerPublicID] = customerQuantities[customerPublicID]
            }
        }

        const unit = {
            amount: amount,
            description: description,
            customer_public_id: publicIdsToBookOffOf,
            notify_by_email: notifyByEmail,
            date: date,
            selectedCustomerAmount:selectedCustomerAmount
        }
        sendUnitToAPi(unit)
    }

    const sendUnitToAPi = (unit: any) => {
        setLoading(true)

        units.addMultiple(unit)
            .then(() => {
                setLoading(false)
                if (persistentStore.user?.settings.useType === 'hours') {
                    toast.success('Tijd afgeboekt')
                } else {
                    const name = unit.amount === 1 ? persistentStore.user?.settings?.stripName.singular : persistentStore.user?.settings?.stripName.plural
                    toast.success(name + ' afgeboekt')
                }

                // reset the form
                resetForm()
            })
            .catch((addMultipleError) => {
                setLoading(false)
                const msgBody = <div dangerouslySetInnerHTML={{ __html: addMultipleError.response.data.errors.detail }} />
                toast.error(<><strong>{addMultipleError.response.data.errors.title}</strong><br /><br />{msgBody}</>)
            })
            .finally(() => {
                //get all customers
                queryCache.invalidateQueries('getCustomers')
                queryCache.invalidateQueries('getCustomerGroups')
            })
    }

    const resetForm = () => {
        setDescription('')
        setAmount(1)
        setDate(new Date())
        setSelectedCustomerPublicIds([])
        setCustomerQuantities({})
    }

    const handleSubmitGroup = (e: any) => {
        e.preventDefault()

        if (groupName === '') {
            setGroupError(t('Enter group name'))
            return
        }

        setLoading(true)

        customersApi.addCustomerGroup(groupName, selectedCustomerPublicIds)
            .then((response) => {
                setLoading(false)

                if (response.data.success) {
                    toast.success(t('Group created'))
                    queryCache.invalidateQueries('getCustomerGroups')
                    setShowModal(false)
                } else {
                    toast.error(response.data.errors.title)
                }
            })
    }

    /**
     * Set the background color of the row when the customer is selected
     */
    const conditionalRowStyles = () => {
        return [{
            when: (customer: ICustomer) => {
                const isSelectElement: boolean = selectedCustomerPublicIds.includes(customer.public_id)
                return isSelectElement
            },
            style: {
                backgroundColor: 'rgb(233 253 226 / 1)',
            }
        }]
    }

    const stripNamePlural = persistentStore.user?.settings?.stripName.plural

    return (
        <>
            <Row>
                <Col>
                    <h2>{useTime ? 'Tijd' : stripNamePlural} afboeken voor meerdere klanten tegelijk</h2>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Jumbotron>
                        <form>
                            <div className="form-row">
                                <Col md={3} className="form-group">
                                    {useTime ? (
                                        <>
                                            <label htmlFor="amountUnits" className="text-left">
                                                {t('Time')}
                                            </label>
                                            <input type="time"
                                                   className="form-control"
                                                   onChange={(e) => {
                                                       handleAmountChange(e.target.value)
                                                   }}
                                                   value={amount}
                                                   disabled={loading}
                                            />
                                        </>

                                    ) : (
                                        <>
                                            <label htmlFor="amountUnits" className="text-left">
                                                Aantal {stripNamePlural}
                                            </label>
                                            <select
                                                className="form-control col-10"
                                                id="amountUnits"
                                                onChange={(e) => {
                                                    handleAmountChange(parseInt(e.target.value, 10))
                                                }}
                                                value={amount}
                                                disabled={loading}
                                            >
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </>
                                    )}
                                </Col>

                                <Col md={9} className="form-group">
                                    <label htmlFor="inlineFormInputName2">Beschrijving</label>
                                    <input type="textarea"
                                           className="form-control"
                                           id="inlineFormInputName2"
                                           onChange={(e) => setDescription(e.target.value)}
                                           placeholder="Bijv: boekhouding bijgewerkt"
                                           value={description}
                                           disabled={loading}
                                    />
                                </Col>
                            </div>

                            <div className="form-row">
                                <Col md={12} className="form-group">
                                    <label htmlFor="inlineFormCreationDate">{t('Creation date')}</label>
                                    <DatePicker
                                        selected={date}
                                        onChange={date => setDate(date)}
                                        dateFormat="dd-MM-yyyy, H:m"
                                        className="form-control"
                                        showTimeSelect
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholderText="Creation date"
                                        locale="nl"
                                    />
                                </Col>
                                <Col md={4} className="form-group">
                                    <Button
                                        id='decrease-strips'
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={addUnit}
                                        disabled={loading}
                                    >
                                        {loading && (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        )}
                                        {!loading && `${useTime ? 'Tijd' : stripNamePlural}  afboeken`}
                                    </Button>
                                </Col>
                                <Col md={8} className="form-group">
                                    <label className="checkbox-inline">
                                        <input
                                            type="checkbox"
                                            id="notify_by_email"
                                            checked={notifyByEmail}
                                            onChange={(event) => setNotifyByEmail(event.target.checked)}
                                            className='mr-1'
                                        />
                                        {t('Notify customer by email')}
                                    </label>
                                </Col>
                            </div>
                        </form>

                        <div className='errorPanel'>
                            {error && <Alert variant='danger'>{error}</Alert>}
                        </div>
                    </Jumbotron>
                </Col>
            </Row>

            <Row>
                <Col md={9}>
                    <h2>{t('Select Customers')}</h2>
                    <p>
                        Als klanten grijs zijn weergegeven dan betekent dit dat zij niet voldoende strippen hebben.
                        Selecteer dan minder strippen om af te boeken of vraag de klant om meer strippen af te nemen.
                    </p>

                    <ToggleSlider
                        size='md'
                        type='round'
                        id='show-archived-customers'
                        checked={showArchivedCustomers}
                        onChange={(checked: boolean) => {
                            setShowArchivedCustomers(checked)
                        }}
                        text={t('Show archived customers')}
                        className='mt-5 mb-2 ml-3'
                    />

                    <CustomersDataTable
                        addCustomer={addCustomerToSelection}
                        amountSelected={amount}
                        conditionalRowStyles={conditionalRowStyles}
                        customerQuantities={customerQuantities}
                        customers={customersQuery}
                        loading={loading}
                        removeCustomerFromSelection={removeCustomerFromSelection}
                        selectedCustomers={selectedCustomerPublicIds}
                        showArchivedCustomers={showArchivedCustomers}
                        updateCustomerAmount={updateCustomerAmount}
                        useTime={useTime}
                    />
                </Col>

                <Col md={3} className={'mt-4 mt-lg-0'}>
                    <CustomerGroups
                        addUsersInGroupToSelection={addGroupToSelection}
                        customerGroups={filteredCustomerGroups}
                        groupError={groupError}
                        handleSubmitGroup={handleSubmitGroup}
                        loading={loading || customerGroupsQuery.isLoading}
                        removeUsersInGroupFromSelection={removeGroupFromSelection}
                        selectedCustomers={selectedCustomerPublicIds}
                        setGroupName={setGroupName}
                        setShowModal={setShowModal}
                        show={showModal}
                    />
                </Col>
            </Row>
        </>
    )
}

export default observer(ClassPage)


