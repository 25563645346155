import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {history, useRouter} from '../../utils/router'
import SweetAlert from 'react-bootstrap-sweetalert/dist'
import {observer} from 'mobx-react'
import {cards as cardsApi} from '../../api/cards'
import {toast} from 'react-toastify'
import {useLoading} from "../../utils/helpers";
import {useTranslation} from "react-i18next";
import {Button, Col, Row, Spinner} from "react-bootstrap";
import DatePicker from "react-datepicker";
import './CardEdit.scss'
import persistentStore from "../../stores/persistentStore";
import {useQuery, useQueryCache} from "react-query";
import {Moment} from "moment/moment";

const CardEdit = () => {
    const [loading, startLoading, stopLoading] = useLoading()
    const [showConfirm, setShowConfirm] = useState(false)
    const {t} = useTranslation()
    const router = useRouter()
    const queryCache = useQueryCache()

    const cardId = parseInt(router.query.id, 10)
    const card = useQuery('card', () => cardsApi.get(cardId))

    const [amount, setAmount] = useState('')
    const [description, setDescription] = useState('')
    const [dueDateUser, setDueDateUser] = useState<Moment | undefined>()
    const [createdUser, setCreatedUser] = useState<Moment | undefined>()

    useEffect(() => {
        if (card.data) {
            setAmount(card.data.amount)
            setDescription(card.data.description || '')

            if (card.data.duedate) {
                const formattedDate: Date = dateMachine2Eur(card.data.duedate)
                setDueDateUser(moment(formattedDate))
            }

            if (card.data.created) {
                const formattedDate: Date = dateMachine2Eur(card.data.created)
                setCreatedUser(moment(formattedDate))
            }
        }
    }, [card.data])

    const handleAmountChange = (changedAmount: any) => {

        // on empty inputfield
        if (changedAmount === '') {
            setAmount('')
            return false
        }

        // parse the value to an integer
        const updatedAmount = parseInt(changedAmount, 10)

        // check whether it's a valid integer
        if (isNaN(updatedAmount)) {
            console.log('invalid character typed')
            return false
        }
        setAmount(updatedAmount.toString())
    }

    // when the form is submitted
    const handleSubmit = (event: any) => {
        event.preventDefault()

        const updatedCard = card.data
        updatedCard.created = createdUser ? fromUserToMachine(createdUser.toISOString()) : undefined
        updatedCard.duedate = dueDateUser ? fromUserToMachine(dueDateUser.toISOString()) : undefined
        updatedCard.amount = amount
        updatedCard.description = description
        startLoading()

        cardsApi.edit(updatedCard)
            .then((data) => {
                stopLoading()
                if (data.success === false) {
                    console.error(data.data.message)
                    toast.error(data.data.message)
                } else {
                    toast.success('Kaart aangepast')
                    queryCache.invalidateQueries(['getCustomer', updatedCard.customer.public_id])
                    persistentStore.updateCustomerFromServer(updatedCard.customer.public_id)
                    history.push(`/customers/${card.data?.customer.public_id}`)
                }
            })
    }

    const deleteCard = (event: any) => {
        // hide popup
        setShowConfirm(false)

        startLoading()
        cardsApi.del(card.data.id)
            // this.props.dispatch(cardActions.del(cardId))
            .then(() => {
                stopLoading()

                // redirect user to the customer screen
                toast.success('Kaart verwijderd')
                persistentStore.updateCustomerFromServer(card.data.customer.public_id)
                const customerUrl = `/customers/${card.data.customer.public_id}`
                history.push(customerUrl)
            })
    }

    // from dd/mm/yyyy => yyyy-mm-dd hh:mm
    const fromUserToMachine = (date: string) => {
        // skip empty values
        if (date === '' || date === undefined) {
            return
        }

        if (!moment(date).isValid()) {
            console.log(date)
            console.log('Moment kan hier niet mee werken')
            return ''
        }

        const eurFormat = 'YYYY-MM-DD HH:mm'
        return moment(date).format(eurFormat)
    }

    const dateMachine2Eur = (machineSuppliedDate: string): Date => {
        // do nothing on empty input
        if (machineSuppliedDate === '' || machineSuppliedDate === undefined) {
            throw new Error('No date supplied. Should not be empty or undefined')
        }

        if (!moment(machineSuppliedDate).isValid()) {
            console.log(machineSuppliedDate)
            console.log('Moment kan hier niet mee werken')
            throw new Error('moment kan hier niet mee werken')
        }

        return moment(machineSuppliedDate).toDate()
    }

    const handleGeldigheidsduur = (event: any) => {
        const options = event.target.value.split(' ')
        let duedate: Moment | undefined

        // just 1 item? Then there is no duedate
        if (options.length === 1) {
            if (options[0] === 'onbeperkt') {
                duedate = undefined
            } else if (options[0] === 'anders') {
                // show the datepicker
            }
        } else if (options.length === 2) {
            const amount = options[0]
            const unit = options[1]
            duedate = moment().add(amount, unit)
        }

        // set the datepicker
        setDueDateUser(duedate)
    }

    return (
        <div className='CardEdit'>
            <h1>{persistentStore.user?.settings?.stripName.plural}kaart wijzigen</h1>

            <form onSubmit={handleSubmit} method="POST">
                <div className="form-group">
                    <label htmlFor="inputDuedate">Verloopdatum (laat leeg voor geen verloopdatum)</label>
                    <Row>
                        <Col md="6">
                            <select
                                id="inputDueDate"
                                className="form-control"
                                name="geldigheidsduur"
                                onChange={handleGeldigheidsduur}
                            >
                                <option value="0">- zoals hiernaast aangegeven</option>
                                <option value="3 weeks">3 weken</option>
                                <option value="12 weeks">12 weken</option>
                                <option value="1 months">1 maand</option>
                                <option value="3 months">3 maanden</option>
                                <option value="6 months">6 maanden</option>
                                <option value="1 years">1 jaar</option>
                            </select>
                        </Col>
                        <Col md="6">
                            <DatePicker
                                selected={dueDateUser?.toDate()}
                                onChange={(date: Date) => date ? setDueDateUser(moment(date.toDateString())) : ''}
                                id="inputDuedate"
                                dateFormat="dd-MM-yyyy"
                                className="form-control"
                                aria-describedby="nameDuedate"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="dd-mm-yyyy"
                                locale="nl"
                                name="duedateUser"
                            />
                        </Col>
                    </Row>
                </div>

                <div className="form-group">
                    <label htmlFor="inputAmount">Aantal {persistentStore.user?.settings?.stripName.plural}</label>
                    <input type="text"
                           className="form-control"
                           id="inputAmount"
                           aria-describedby="nameHelp"
                           onChange={(event) => handleAmountChange(event.target.value)}
                           value={amount ?? ''}
                           placeholder="laden..."
                           autoComplete="off"
                           name="amount"
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="inputDescription">{t('Description')}</label>
                    <textarea
                        className="form-control"
                        name="description"
                        id="inputDescription"
                        onChange={(event) => setDescription(event.target.value)}
                        placeholder={t("Description")}
                        value={description}
                    />
                </div>

                {showConfirm && (
                    <SweetAlert
                        warning
                        showCancel
                        allowEscape={true}
                        closeOnClickOutside={true}
                        confirmBtnText="Ja, verwijder de kaart"
                        confirmBtnBsStyle="danger"
                        title="Weet je het zeker?"
                        onConfirm={deleteCard}
                        onCancel={() => setShowConfirm(false)}
                        focusCancelBtn
                    >
                        Je kunt het verwijderen van de strippenkaart niet ongedaan maken!
                    </SweetAlert>
                )}

                <Button variant='outline-danger' disabled={card.isLoading} onClick={() => setShowConfirm(true)}>
                    {t('Delete')}
                </Button>
                <Link to={`/customers/${card.data?.customer?.public_id}`}>
                    <button type="button" className="btn btn-link">{t('Cancel')}</button>
                </Link>
                &nbsp;
                <button id='card-save' disabled={card.isLoading} type="submit" className="btn btn-primary">
                    {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                    {!loading && t('Save')}
                </button>
            </form>
        </div>
    )
}

export default observer(CardEdit)
