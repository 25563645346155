import {Row} from "react-bootstrap";
import React from "react";
import {NewCustomerCard} from "./NewCustomerCard";
import {ICustomer} from "../../interfaces/ICustomer";
import {IUser} from "../../interfaces/IUser";
import CustomerCardMobile from "./CustomerCardMobile";

type CustomerGridViewProps = {
    customers: ICustomer[],
    user: IUser,
    loading: boolean
    addCustomer: (customerPublicId: string) => void,
    removeCustomerFromSelection: (customerPublicId: string) => void,
    selectedCustomers: string[],
    groupClicked: boolean
}

const CustomerGridView = (props: CustomerGridViewProps) => {
    const alphaOrderedCustomers: ICustomer[] = props.customers.sort((a, b) => {
        return a.name.localeCompare(b.name)
    })

    return (
        <Row className='customer-grid-view d-flex'>
            {alphaOrderedCustomers.map((customer, index: number) => (
                <React.Fragment key={index}>
                    <CustomerCardMobile
                        className='CustomerCard mb-2 flex-grow-1 flex-lg-grow-0'
                        customer={customer}
                        user={props.user}
                        addCustomer={props.addCustomer}
                        removeCustomer={props.removeCustomerFromSelection}
                        selectedCustomers={props.selectedCustomers}
                        groupClicked={props.groupClicked}
                    />
                </React.Fragment>
            ))}

            <NewCustomerCard/>
        </Row>
    )
}

export default CustomerGridView
